<template>
<v-row no-gutters>
<v-col cols="12" md="6">
  <v-row no-gutters class="pt-2">
    <v-col cols="12" md="4" class="pl-2">
      <v-text-field label="Cash Type" v-model="cashtype.typeName" dense/>
    </v-col>
    <v-col cols="12" md="3" class="pl-2">
      <v-text-field label="Unit" v-model="cashtype.unitAmount" dense/>
    </v-col>
    <v-col cols="6" md="3" class="pl-2">
            <v-combobox
              v-model="cashtype.unit"
              :items="unitList"
              label="Unit"
              item-text="unit"
              return-object
              requiblue
              dense
            ></v-combobox>
          </v-col>
    <v-col cols="12" md="2" class="pl-2">
<v-btn  class="primary" @click="saveCash()" >
            {{SaveOrUpdate}}
          </v-btn>
    </v-col>
    <v-col cols="12" md="12" class="pt-1">
<v-simple-table
        :fixed-header="true"
        dense
        class="elevation-1"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center white--text primary">No.</th>
              <th class="text-center white--text primary">
                <v-divider vertical></v-divider>
              </th>
              <th class="text-center white--text primary">Name</th>
              <th class="text-center white--text primary">
                <v-divider vertical></v-divider>
              </th>
              <th class="text-center white--text primary">Unit Amount</th>
              <th class="text-center white--text primary">
                <v-divider vertical></v-divider>
              </th>
              <th class="text-center white--text primary">unit</th>
              <th class="text-center white--text primary">
                <v-divider vertical></v-divider>
              </th>
              <th class="text-center white--text primary">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in cashList"
              :key="index"
            >
              <td class="text-center">{{ index + 1 }}</td>
              <td class="text-center"><v-divider vertical></v-divider></td>
              <td class="text-left">{{ item.typeName }}</td>
              <td class="text-center"><v-divider vertical></v-divider></td>
              <td class="text-center">{{ item.unitAmount }}</td>
              <td class="text-center"><v-divider vertical></v-divider></td>
              <td class="text-center">{{ item.unit }}</td>
              <td class="text-center"><v-divider vertical></v-divider></td>
              <td class="text-center">
                <v-btn v-if="uiAccept.typeedit" small icon color="green" @click="clickEditCash(item)">
                  <v-icon>edit</v-icon></v-btn
                >
                <v-btn v-if="uiAccept.typedelete" small icon color="red" @click="clickDeleteCash(item)">
                  <v-icon>delete</v-icon></v-btn
                >
              </td>
            </tr>
            <v-divider></v-divider>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
  </v-col>
<!-- <v-col cols="12" md="4">
  <v-row no-gutters class="pt-2 ml-2">
    <v-col cols="12" md="8">
      <v-text-field label="Income Type" v-model="income.description" dense/>
    </v-col>
    <v-col cols="12" md="4">
<v-btn  class="blue white--text ml-2" @click="saveIncome()" >
            {{incomeOrupdate}}
          </v-btn>
    </v-col>
    <v-col cols="12" md="12" class="pt-1">
<v-simple-table
        :fixed-header="true"
        dense
        class="elevation-1"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center white--text blue">No.</th>
              <th class="text-center white--text blue">
                <v-divider vertical></v-divider>
              </th>
              <th class="text-center white--text blue">Name</th>
              <th class="text-center white--text blue">
                <v-divider vertical></v-divider>
              </th>
              <th class="text-center white--text blue">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(income, index) in incomeList"
              :key="index"
            >
              <td class="text-center">{{ index + 1 }}</td>
              <td class="text-center"><v-divider vertical></v-divider></td>
              <td class="text-left">{{ income.description }}</td>
              <td class="text-center"><v-divider vertical></v-divider></td>
              <td class="text-center">
                <v-btn small icon color="green" @click="clickEditIncome(income)">
                  <v-icon>edit</v-icon></v-btn
                >
                <v-btn small icon color="blue" @click="clickDeleteIncome(income)">
                  <v-icon>delete</v-icon></v-btn
                >
              </td>
            </tr>
            <v-divider></v-divider>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
  </v-col> -->
  <v-col cols="12" md="6">
    <v-row no-gutters class="pt-2 ml-2">
    <v-col cols="12" md="8">
      <v-text-field label="Payment Type" v-model="expense.description" dense/>
    </v-col>
    <v-col cols="12" md="4">
<v-btn  class="blue white--text ml-2" @click="saveExpense()" >
            {{expenseOrupdate}}
          </v-btn>
    </v-col>
    <v-col cols="12" md="12" class="pt-1">
<v-simple-table
        :fixed-header="true"
        dense
        class="elevation-1"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center white--text blue">No.</th>
              <th class="text-center white--text blue">
                <v-divider vertical></v-divider>
              </th>
              <th class="text-center white--text blue">Name</th>
              <th class="text-center white--text blue">
                <v-divider vertical></v-divider>
              </th>
              <th class="text-center white--text blue">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(expense, index) in expenseList"
              :key="index"
            >
              <td class="text-center">{{ index + 1 }}</td>
              <td class="text-center"><v-divider vertical></v-divider></td>
              <td class="text-left">{{ expense.description }}</td>
              <td class="text-center"><v-divider vertical></v-divider></td>
              <td class="text-center">
                <v-btn v-if="uiAccept.typeedit" small icon color="green" @click="clickEditExpense(expense)">
                  <v-icon>edit</v-icon></v-btn
                >
                <v-btn v-if="uiAccept.typedelete" small icon color="red" @click="clickDeleteExpense(expense)">
                  <v-icon>delete</v-icon></v-btn
                >
              </td>
            </tr>
            <v-divider></v-divider>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
  </v-col>
</v-row>
</template>
<script>
import billService from "../service/BillService";
export default {
  data: () => ({
    SaveOrUpdate:"SAVE",
    cashList:[],
    cashtype:{unit:""},
    incomeList:[],
    income:{},
    incomeOrupdate:"SAVE",
    expenseList:[],
    expense:{},
    expenseOrupdate:"SAVE",
    unitList:['MMK','$'],
    uiAccept:{},
  }),
   props: {},
  mounted: function() {
    this.uiAccept = this.$store.state.uiAccept;
    this.getCashListMethod();
    this.getIncomeListMethod();
     this.getExpenseListMethod();
     this.cashtype.unit = this.unitList[0];
  },
  methods: {
    getCashListMethod:function(){
billService
        .getCashTypes()
        .then((response) => {
          this.cashList.splice(0);
          this.cashList.push(...response);
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
    },
    getIncomeListMethod:function(){
billService
        .getIncomeTypes()
        .then((response) => {
          this.incomeList.splice(0);
          this.incomeList.push(...response);
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
    },
    getExpenseListMethod:function(){
billService
        .getExpenseTypes()
        .then((response) => {
          this.expenseList.splice(0);
          this.expenseList.push(...response);
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
    },
    saveCash:function(){
      if(this.cashtype.typeName!="" && this.cashtype.typeName!=undefined){
      if(this.SaveOrUpdate == "SAVE"){
      billService
        .saveCash(this.cashtype)
        .then((response) => {
          this.getCashListMethod();
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
      }else{
        billService
        .updateCash(this.cashtype)
        .then((response) => {
          this.getCashListMethod();
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
      }
      }
            this.cashtype = {};
            this.cashtype.unit = this.unitList[0];
      this.SaveOrUpdate = "SAVE";
    },
    saveIncome:function(){
      if(this.income.description!="" && this.income.description!=undefined){
      if(this.incomeOrupdate == "SAVE"){
      billService
        .saveIncome(this.income)
        .then((response) => {
          this.getIncomeListMethod();
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
      }else{
        billService
        .updateIncome(this.income)
        .then((response) => {
          this.getIncomeListMethod();
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
      }
      }
            this.income = {};
      this.incomeOrupdate = "SAVE";
    },
    clickEditCash:function(item){
      this.cashtype = Object.assign({},item);
      this.SaveOrUpdate = "UPDATE";
    },
    clickEditIncome:function(item){
      this.income = Object.assign({},item);
      this.incomeOrupdate = "UPDATE";
    },
    clickDeleteCash:function(item){
      billService
        .deleteCash(item)
        .then((response) => {
          this.getCashListMethod();
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
    },
    clickDeleteIncome:function(item){
      billService
        .deleteIncome(item)
        .then((response) => {
          this.getIncomeListMethod();
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
    },

     saveExpense:function(){
      if(this.expense.description!="" && this.expense.description!=undefined){
      if(this.expenseOrupdate == "SAVE"){
      billService
        .saveExpense(this.expense)
        .then((response) => {
          this.getExpenseListMethod();
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
      }else{
        billService
        .updateExpense(this.expense)
        .then((response) => {
          this.getExpenseListMethod();
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
      }
      }
            this.expense = {};
      this.expenseOrupdate = "SAVE";
    },
    clickEditExpense:function(item){
      this.expense = Object.assign({},item);
      this.expenseOrupdate = "UPDATE";
    },
    clickDeleteExpense:function(item){
      billService
        .deleteExpense(item)
        .then((response) => {
          this.getExpenseListMethod();
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
    },
  },
  watch: {},
  components: {}
};
</script>
<style scoped>
.v-data-table td,
.v-data-table th {
  padding: 0 0px !important;
}
</style>